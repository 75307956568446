import {
  I_CallToActionConfigGlobal,
  I_ConfigThemeBaseKrpano,
  I_ConfigThemeBlank,
  I_ConfigThemeCustom1,
  I_ConfigThemeDefault,
  I_ConfigThemeWall,
  I_FormCaptureConfigGlobal,
} from 'app/containers/Editors/Design/types';
import { I_OptionsGetList } from 'types';

export enum TYPE_LOCAL_STORAGE {
  URL_REDIRECT = 'panoee-studio-url-redirect',
  IS_DISMISS_QUICK_NOTIFICATION = 'panoee-studio-is-dismiss-quick-notification',
  LAST_CONFIG_HOTSPOT = 'last-config-hotspot',
  IS_HIDE_CHAT = 'is-hide-chat-plugin',
}

export enum TYPE_COOKIE {
  TOKEN = 'panoee-studio-token',
  AFFILIATE_ID = 'panoee-studio-affiliate-id',
}

export const LIMIT_PAGE_SIZE = 20;

export const default_options_get_list: I_OptionsGetList = {
  limit: LIMIT_PAGE_SIZE,
  page: 1,
  search: '',
  order_by: 'updated_at',
  order: 'desc',
};

export const STYLE_DARK_THEME_CONTENT_DIALOG = {
  color: '#fff',
  // border: '1px solid rgb(255 255 255 / .15)',
  backgroundColor: '#212121',
  // boxShadow: '0 12px 24px rgb(0 0 0  / .15)',
};

export const STYLE_DARK_THEME_CONTAINER_DIALOG = {
  boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.1)',
  border: '1px solid red',
};

export const LENSFLARE_NAME = 'lensflare_plugin';

export const LENSFLARE_HOTSPOT = {
  title: '',
  type: 'point' as const,
  width: 50,
  height: 50,
  x: 0,
  y: 0,
  z: 0,
  config: {
    style: {
      type: 'image' as const,
      image: {
        src:
          'https://www.freeiconspng.com/thumbs/sun/natural-energy-source-the-sun-transparent-photos-3.png',
      },
    },
  },
  polygon: false,
  id: LENSFLARE_NAME,
};

export enum TYPE_DRAG_N_DROP {
  HOTSPOT = 'hotspot',
  SCENE = 'scene',
  SCENE_CHILD = 'scene_child',
  GROUP = 'group',
  HOTSPOT_IMAGE = 'hotspot_image',
  FLOORPLAN = 'floorplan',
  FLOORPLAN_MARKER = 'floorplan_marker',
  LENSFLARE_PLUGIN = 'lensflare_plugin',
}

export const LIST_ANIMATE = [
  {
    label: 'Normal',
    key: 'normal',
  },
  {
    label: 'Pulse Grow',
    key: 'pulse-grow',
  },
  {
    label: 'Raise',
    key: 'raise',
  },
  {
    label: 'Bounce',
    key: 'animate__bounce',
  },
  {
    label: 'Flash',
    key: 'animate__flash',
  },
  {
    label: 'Pulse',
    key: 'animate__pulse',
  },
  {
    label: 'Rubber Band',
    key: 'animate__rubberBand',
  },
  {
    label: 'Shake X',
    key: 'animate__shakeX',
  },
  {
    label: 'Shake Y',
    key: 'animate__shakeY',
  },
  {
    label: 'Head Shake',
    key: 'animate__headShake',
  },
  {
    label: 'Swing',
    key: 'animate__swing',
  },
  {
    label: 'Tada',
    key: 'animate__tada',
  },
  {
    label: 'Wobble',
    key: 'animate__wobble',
  },
  {
    label: 'Jello',
    key: 'animate__jello',
  },
  {
    label: 'Heart Beat',
    key: 'animate__heartBeat',
  },
  // {
  //   label: 'Back In Down',
  //   key: 'animate__backInDown',
  // },
  // {
  //   label: 'Back In Left',
  //   key: 'animate__backInLeft',
  // },
  // {
  //   label: 'Back In Right',
  //   key: 'animate__backInRight',
  // },
  // {
  //   label: 'Back In Up',
  //   key: 'animate__backInUp',
  // },
  // {
  //   label: 'Back Out Down',
  //   key: 'animate__backOutDown',
  // },
  // {
  //   label: 'Back Out Left',
  //   key: 'animate__backOutLeft',
  // },
  // {
  //   label: 'Back Out Right',
  //   key: 'animate__backOutRight',
  // },
  // {
  //   label: 'Back Out Up',
  //   key: 'animate__backOutUp',
  // },
  // {
  //   label: 'Bounce In',
  //   key: 'animate__bounceIn',
  // },
  // {
  //   label: 'Bounce In Down',
  //   key: 'animate__bounceInDown',
  // },
  // {
  //   label: 'Bounce In Left',
  //   key: 'animate__bounceInLeft',
  // },
  // {
  //   label: 'Bounce In Right',
  //   key: 'animate__bounceInRight',
  // },
  // {
  //   label: 'Bounce In Up',
  //   key: 'animate__bounceInUp',
  // },
  {
    label: 'Bounce Out',
    key: 'animate__bounceOut',
  },
  // {
  //   label: 'Bounce Out Down',
  //   key: 'animate__bounceOutDown',
  // },
  // {
  //   label: 'Bounce Out Left',
  //   key: 'animate__bounceOutLeft',
  // },
  // {
  //   label: 'Bounce Out Right',
  //   key: 'animate__bounceOutRight',
  // },
  // {
  //   label: 'Bounce Out Up',
  //   key: 'animate__bounceOutUp',
  // },
  {
    label: 'Fade In',
    key: 'animate__fadeIn',
  },
  {
    label: 'Fade In Down',
    key: 'animate__fadeInDown',
  },
  // {
  //   label: 'Fade In Down Big',
  //   key: 'animate__fadeInDownBig',
  // },
  {
    label: 'Fade In Left',
    key: 'animate__fadeInLeft',
  },
  // {
  //   label: 'Fade In Left Big',
  //   key: 'animate__fadeInLeftBig',
  // },
  {
    label: 'Fade In Right',
    key: 'animate__fadeInRight',
  },
  // {
  //   label: 'Fade In Right Big',
  //   key: 'animate__fadeInRightBig',
  // },
  {
    label: 'Fade In Up',
    key: 'animate__fadeInUp',
  },
  // {
  //   label: 'Fade In Up Big',
  //   key: 'animate__fadeInUpBig',
  // },
  // {
  //   label: 'Fade In Top Left',
  //   key: 'animate__fadeInTopLeft',
  // },
  // {
  //   label: 'Fade In Top Right',
  //   key: 'animate__fadeInTopRight',
  // },
  // {
  //   label: 'Fade In Bottom Left',
  //   key: 'animate__fadeInBottomLeft',
  // },
  // {
  //   label: 'Fade In Bottom Right',
  //   key: 'animate__fadeInBottomRight',
  // },
  // {
  //   label: 'Fade Out',
  //   key: 'animate__fadeOut',
  // },
  // {
  //   label: 'Fade Out Down',
  //   key: 'animate__fadeOutDown',
  // },
  // {
  //   label: 'Fade Out Down Big',
  //   key: 'animate__fadeOutDownBig',
  // },
  // {
  //   label: 'Fade Out Left',
  //   key: 'animate__fadeOutLeft',
  // },
  // {
  //   label: 'Fade Out Left Big',
  //   key: 'animate__fadeOutLeftBig',
  // },
  // {
  //   label: 'Fade Out Right',
  //   key: 'animate__fadeOutRight',
  // },
  // {
  //   label: 'Fade Out Right Big',
  //   key: 'animate__fadeOutRightBig',
  // },
  // {
  //   label: 'Fade Out Up',
  //   key: 'animate__fadeOutUp',
  // },
  // {
  //   label: 'Fade Out Up Big',
  //   key: 'animate__fadeOutUpBig',
  // },
  // {
  //   label: 'Fade Out Top Left',
  //   key: 'animate__fadeOutTopLeft',
  // },
  // {
  //   label: 'Fade Out Top Right',
  //   key: 'animate__fadeOutTopRight',
  // },
  // {
  //   label: 'Fade Out Bottom Right',
  //   key: 'animate__fadeOutBottomRight',
  // },
  // {
  //   label: 'Fade Out Bottom Left',
  //   key: 'animate__fadeOutBottomLeft',
  // },
  {
    label: 'Flip',
    key: 'animate__flip',
  },
  {
    label: 'Flip In X',
    key: 'animate__flipInX',
  },
  {
    label: 'Flip In Y',
    key: 'animate__flipInY',
  },
  // {
  //   label: 'Flip Out X',
  //   key: 'animate__flipOutX',
  // },
  // {
  //   label: 'Flip Out Y',
  //   key: 'animate__flipOutY',
  // },
  // {
  //   label: 'Light Speed In Right',
  //   key: 'animate__lightSpeedInRight',
  // },
  // {
  //   label: 'Light Speed In Left',
  //   key: 'animate__lightSpeedInLeft',
  // },
  // {
  //   label: 'Light Speed Out Right',
  //   key: 'animate__lightSpeedOutRight',
  // },
  // {
  //   label: 'Light Speed Out Left',
  //   key: 'animate__lightSpeedOutLeft',
  // },
  // {
  //   label: 'Rotate In',
  //   key: 'animate__rotateIn',
  // },
  // {
  //   label: 'Rotate In Down Left',
  //   key: 'animate__rotateInDownLeft',
  // },
  // {
  //   label: 'Rotate In Down Right',
  //   key: 'animate__rotateInDownRight',
  // },
  // {
  //   label: 'Rotate In Up Left',
  //   key: 'animate__rotateInUpLeft',
  // },
  // {
  //   label: 'Rotate In Up Right',
  //   key: 'animate__rotateInUpRight',
  // },
  // {
  //   label: 'Rotate Out',
  //   key: 'animate__rotateOut',
  // },
  // {
  //   label: 'Rotate Out Down Left',
  //   key: 'animate__rotateOutDownLeft',
  // },
  // {
  //   label: 'Rotate Out Down Right',
  //   key: 'animate__rotateOutDownRight',
  // },
  // {
  //   label: 'Rotate Out Up Left',
  //   key: 'animate__rotateOutUpLeft',
  // },
  // {
  //   label: 'Rotate Out Up Right',
  //   key: 'animate__rotateOutUpRight',
  // },
  {
    label: 'Hinge',
    key: 'animate__hinge',
  },
  {
    label: 'Jack In The Box',
    key: 'animate__jackInTheBox',
  },
  {
    label: 'Roll In',
    key: 'animate__rollIn',
  },
  // {
  //   label: 'Roll Out',
  //   key: 'animate__rollOut',
  // },
  {
    label: 'Zoom In',
    key: 'animate__zoomIn',
  },
  // {
  //   label: 'Zoom In Down',
  //   key: 'animate__zoomInDown',
  // },
  // {
  //   label: 'Zoom In Left',
  //   key: 'animate__zoomInLeft',
  // },
  // {
  //   label: 'Zoom In Right',
  //   key: 'animate__zoomInRight',
  // },
  // {
  //   label: 'Zoom In Up',
  //   key: 'animate__zoomInUp',
  // },
  // {
  //   label: 'Zoom Out',
  //   key: 'animate__zoomOut',
  // },
  // {
  //   label: 'Zoom Out Down',
  //   key: 'animate__zoomOutDown',
  // },
  // {
  //   label: 'Zoom Out Left',
  //   key: 'animate__zoomOutLeft',
  // },
  // {
  //   label: 'Zoom Out Right',
  //   key: 'animate__zoomOutRight',
  // },
  // {
  //   label: 'Zoom Out Up',
  //   key: 'animate__zoomOutUp',
  // },
  // {
  //   label: 'Slide In Down',
  //   key: 'animate__slideInDown',
  // },
  // {
  //   label: 'Slide In Left',
  //   key: 'animate__slideInLeft',
  // },
  // {
  //   label: 'Slide In Right',
  //   key: 'animate__slideInRight',
  // },
  // {
  //   label: 'Slide In Up',
  //   key: 'animate__slideInUp',
  // },
  // {
  //   label: 'Slide Out Down',
  //   key: 'animate__slideOutDown',
  // },
  // {
  //   label: 'Slide Out Left',
  //   key: 'animate__slideOutLeft',
  // },
  // {
  //   label: 'Slide Out Right',
  //   key: 'animate__slideOutRight',
  // },
  // {
  //   label: 'Slide Out Up',
  //   key: 'animate__slideOutUp',
  // },
];

export const CONFIG_THEME_COLOR_DEFAULT: {
  basekrpano: I_ConfigThemeBaseKrpano['config'];
  default: I_ConfigThemeDefault['config'];
  custom1: I_ConfigThemeCustom1['config'];
  blank: I_ConfigThemeBlank['config'];
  wall: I_ConfigThemeWall['config'];
} = {
  default: {
    primaryColor: '#ffffff',
    textColor: '#000000',
  },
  custom1: {
    primaryColor: '#D3015B',
    secondaryColor: '#F7C003',
    textColor: '#ffffff',
  },
  wall: {
    primaryColor: '#00000080',
    textColor: '#ffffff',
    autoOpenSceneListDesktop: true,
    autoOpenSceneListMobile: false,
  },
  basekrpano: {
    primaryColor: '#00000080',
    textColor: '#ffffff',
    autoOpenSceneList: true,
  },
  blank: {
    primaryColor: '#00000080',
    textColor: '#ffffff',
    isHideInfoTour: false,
  },
};

export const CONFIG_POLYGON_HOTSPOT_DEFAULT = {
  fillColor: '#ffffff20',
  fillColorHover: '#ffffff50',
  borderWidth: 3,
  borderColor: '#ffffff',
  borderColorHover: '#ffffff',
};

export const COLOR_HOTSPOT_BG_DEFAULT = '#E54D42';
export const COLOR_HOTSPOT_ICON_DEFAULT = '#ffffff';

export const COLOR_POPUP_BG_DEFAULT = '#ffffff';
export const COLOR_POPUP_TEXT_DEFAULT = '#000000';

export const COLOR_BUTTON_PRODUCT_DEFAULT = '#2ec26a';

export const CONFIG_FORM_CAPTURE_DEFAULT: I_FormCaptureConfigGlobal = {
  backgroundColor: '#fff',
  overlayColor: '#00000073',
  layout: {
    type: 'dialog',
  },
};

export const CONFIG_CALL_TO_ACTION_DEFAULT: I_CallToActionConfigGlobal = {
  layout: 'list',
  position: 'left',
  offsetLeft: '12',
  offsetRight: '12',
  offsetBottom: '165',
};
