import {
  I_PaylaodChangePassword,
  I_PayloadEditProfile,
} from 'app/containers/Profile/types';
import { BaseXHR } from 'utils/axios';

export const callApiPutAvatar = payload =>
  BaseXHR.$put('/account/avatar', payload);

export const callApiPutCover = payload =>
  BaseXHR.$put('/account/cover', payload);

export const callApiPutProfile = (payload: I_PayloadEditProfile) =>
  BaseXHR.$put('/account/profile', payload);

export const updateRefreshTokenGoogleUserApi = (payload: {
  code: string;
  redirect_uri: string;
}) => BaseXHR.$put('/account/putRefreshTokenGoogle', payload);

export const callApiChangePassword = (payload: I_PaylaodChangePassword) =>
  BaseXHR.$put('/account/change-password', payload);
