import { createRoutine } from 'redux-saga-routines';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  I_AwsConfig,
  I_Media,
  I_MediaUploading,
  I_OptionsGetListMedia,
  I_OptionUploadMedia,
  I_User,
  I_Album,
  I_PayloadDeletesOrMovesMedia,
  I_SubscriptionInfo,
  I_MediaBuiltIn,
} from './types';
import { clearAllLS } from 'utils/localStorage';
import { TYPE_COOKIE } from 'utils/constants';
import { removeCookie } from 'utils/cookies';
import { calcStorage } from 'utils/helper';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  infoUser: undefined,
  isLogged: false,
  subscriptionInfo: undefined,
  featureRequestVoted: [],
  /**
   * ! MEDIA state
   */
  media: {
    error: false,
    loading: 0,
    success: false,
    listMedia: [],
    listMediaBuiltInHotspotImage: [],
    listMediaBuiltInHotspotLottie: [],
    listMediaBuiltInHotspotMarker: [],
    listMediaUploading: [],
    listAlbum: [],
    albumSelected: undefined,
    optionsGetListMedia: {
      limit: 20,
      page: 1,
      search: '',
      order: 'desc',
      order_by: 'created_at',
      type: 'pano',
      mimetype: ['image'],
      isFolder: false,
      folder: '',
    },
    optionsGetListAlbum: {
      limit: 1000,
      page: 1,
      search: '',
      order: 'desc',
      order_by: 'created_at',
      isFolder: true,
      folder: '',
    },
    totalPageMedia: 1,
    totalDocMedia: 0,
    loadingDeletesOrMoves: 0,
    successDeletesOrMoves: false,
  },

  aws: {
    bucket: undefined,
    region: undefined,
    accessKeyId: undefined,
    sessionToken: undefined,
    secretAccessKey: undefined,
    // bucket: 'panoee-galleries',
    // region: 'ap-southeast-1',
    // accessKeyId: 'ASIARFWX3UVXS4BIMBW6',
    // sessionToken: `FwoGZXIvYXdzEJz//////////wEaDGk3vlCctuQ2HAnnhyKsAcWtmktFvVhiNjIA2fWYBXwYUcwKl4re1xAdjk3ug5G5uVqBKLYnwHLhgW2h8YdSn1URtqohDyteEFnKpd/YT5/fVNp9hL6EEw/ReLFx6sMXNEDpsILB/6aRI1bU9LnumQHpaalr2b6YQaaI3jAt7T2A0M4Uj2p/I/vfArqNjx/LG4xYQvTz6jpb3ucFZksax8QsGK0AFN3zn8yrEP2oOMjwOp6cRZNCMaimiBEolazlmAYyLfCSB7RpD0KDbs+0yFbo/6m2uG6SsTGnGhSleGQMC3+WK0jVSO1TyLQz3iYcMQ==`,
    // secretAccessKey: 'SssbG/8+1+Tm5mHTmFyoQaVhd8Kq+PWCX9fb/SgM',
  },
};

export const GET_ME = createRoutine('app/getMe');
export const GET_AWS = createRoutine('app/getAws');
export const GET_LIST_MEDIA = createRoutine('app/getListMedia');
export const GET_LIST_MEDIA_BUILT_IN = createRoutine('app/getListMediaBuiltIn');
export const GET_LIST_ALBUM = createRoutine('app/getListAlbum');
export const CREATE_ALBUM = createRoutine('app/createAlbum');
export const UPLOAD_MEDIA = createRoutine('app/uploadMedia');
export const DELETES_OR_MOVES_MEDIA = createRoutine('app/deletesOrMovesMedia');

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeIsLogged(state, action: PayloadAction<{ isLogged: boolean }>) {
      state.isLogged = action.payload.isLogged;
    },

    changeStateLoading(state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading;
    },

    setRefreshTokenGoogle(state, action: PayloadAction<string>) {
      if (state.infoUser) state.infoUser.refresh_token_google = action.payload;
    },

    setInfoUser(state, action: PayloadAction<I_User | undefined>) {
      state.infoUser = state.infoUser
        ? { ...state.infoUser, ...action.payload }
        : action.payload;
    },

    setStorageOfUser(
      state,
      action: PayloadAction<{ type: 'add' | 'delete'; media: I_Media }>,
    ) {
      if (state.infoUser) {
        const { type, media } = action.payload;
        let newSize = calcStorage(
          state.infoUser.size || 0,
          type,
          media.size,
          media.type,
        );
        state.infoUser = {
          ...state.infoUser,
          ...{ size: newSize },
        };
      }
    },

    setSubscriptionInfo(
      state,
      action: PayloadAction<I_SubscriptionInfo | undefined>,
    ) {
      state.subscriptionInfo = state.subscriptionInfo
        ? {
            ...state.subscriptionInfo,
            ...action.payload,
          }
        : action.payload;
    },

    setFeatureRequestVoted(state, action: PayloadAction<string[] | undefined>) {
      state.featureRequestVoted = action.payload || [];
    },

    logout(state) {
      state.isLogged = initialState.isLogged;
      state.infoUser = initialState.infoUser;
      removeCookie(TYPE_COOKIE.TOKEN);
      clearAllLS();
      state = initialState;
    },

    /**
     * ! UPDATE media via SOCKET events
     */
    updateMedia(state, action: PayloadAction<Partial<I_Media>>) {
      state.media.listMedia = state.media.listMedia.map(item =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item,
      );
    },

    /**
     * ! MEDIA
     *  */

    setOptionPaginationMedia(
      state,
      action: PayloadAction<{
        totalPageMedia?: number;
        totalDocMedia?: number;
      }>,
    ) {
      const { totalPageMedia, totalDocMedia } = action.payload;
      totalPageMedia !== undefined &&
        (state.media.totalPageMedia =
          action.payload.totalPageMedia || initialState.media.totalPageMedia);
      totalDocMedia !== undefined &&
        (state.media.totalDocMedia =
          action.payload.totalDocMedia || initialState.media.totalDocMedia);
    },

    setOptionsGetListMedia(
      state,
      action: PayloadAction<Partial<I_OptionsGetListMedia>>,
    ) {
      const {
        search,
        page,
        limit,
        order,
        order_by,
        type,
        mimetype,
        folder,
      } = action.payload;
      folder !== undefined &&
        (state.media.optionsGetListMedia.folder = folder || '');
      search !== undefined &&
        (state.media.optionsGetListMedia.search = search || '');
      mimetype !== undefined &&
        (state.media.optionsGetListMedia.mimetype = mimetype || '');
      limit !== undefined &&
        (state.media.optionsGetListMedia.limit =
          limit || initialState.media.optionsGetListMedia.limit);
      if (page !== undefined) {
        state.media.optionsGetListMedia.page =
          page || initialState.media.optionsGetListMedia.page;
        if (page === 1) {
          state.media.listMedia = [];
          state.media.totalPageMedia = 1;
          // state.media.totalDocMedia = 0;
        }
      }
      order !== undefined &&
        (state.media.optionsGetListMedia.order =
          order || initialState.media.optionsGetListMedia.order);
      order_by !== undefined &&
        (state.media.optionsGetListMedia.order_by =
          order_by || initialState.media.optionsGetListMedia.order_by);
      type !== undefined &&
        (state.media.optionsGetListMedia.type =
          type || initialState.media.optionsGetListMedia.type);
    },

    addMediaUploadingProgress(state, action: PayloadAction<I_MediaUploading>) {
      state.media.listMediaUploading = [
        action.payload,
        ...state.media.listMediaUploading,
      ];
    },

    removeMediaUploadingProgress(state, action: PayloadAction<string>) {
      state.media.listMediaUploading = state.media.listMediaUploading.filter(
        item => item.id !== action.payload,
      );
    },

    updateMediaUploadingProgress(
      state,
      action: PayloadAction<I_MediaUploading>,
    ) {
      state.media.listMediaUploading = state.media.listMediaUploading?.map(
        item =>
          item.id === action.payload.id &&
          (item.progress || 0) < (action.payload?.progress || 1)
            ? action.payload
            : item,
      );
    },

    setAlbumSelected(state, action: PayloadAction<I_Album>) {
      state.media.albumSelected = action.payload;
    },

    addAlbum(state, action: PayloadAction<I_Album>) {
      state.media.listAlbum = [action.payload, ...state.media.listAlbum];
    },

    updateAlbum(state, action: PayloadAction<I_Album>) {
      let index = state.media.listAlbum.findIndex(
        item => item.id === action.payload.id,
      );
      state.media.listAlbum[index] = action.payload;
    },

    deleteAlbum(state, action: PayloadAction<I_Album>) {
      state.media.listAlbum = state.media.listAlbum.filter(
        item => item.id !== action.payload.id,
      );
    },

    /**
     * ! RESET state
     */

    resetAws(state) {
      Object.keys(state.aws).forEach(item => {
        state.aws[item] = initialState.aws[item];
      });
    },

    resetMedia(state) {
      Object.keys(state.media).forEach(item => {
        state.media[item] = initialState.media[item];
      });
    },

    resetState(state) {
      Object.keys(state).forEach(item => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers: {
    /**
     * !GET LIST MEDIA
     */
    [GET_LIST_MEDIA.TRIGGER]: (
      state,
      action: PayloadAction<I_OptionsGetListMedia>,
    ) => {
      state.media.loading++;
      state.media.success = false;
      state.media.error = false;
    },
    [GET_LIST_MEDIA.SUCCESS]: (state, action: PayloadAction<I_Media[]>) => {
      state.media.listMedia = [...state.media.listMedia, ...action.payload];
      state.media.loading--;
      state.media.success = true;
      state.media.error = false;
    },
    [GET_LIST_MEDIA.FAILURE]: state => {
      state.media.loading--;
      state.media.success = false;
      state.media.error = true;
    },

    /**
     * !GET LIST MEDIA BUILT-IN
     */
    [GET_LIST_MEDIA_BUILT_IN.TRIGGER]: state => {},
    [GET_LIST_MEDIA_BUILT_IN.SUCCESS]: (
      state,
      action: PayloadAction<{
        hotspotImages: I_MediaBuiltIn[];
        hotspotMarkers: I_MediaBuiltIn[];
        hotspotLotties: I_MediaBuiltIn[];
      }>,
    ) => {
      state.media.listMediaBuiltInHotspotMarker =
        action.payload.hotspotMarkers || [];
      state.media.listMediaBuiltInHotspotImage =
        action.payload.hotspotImages || [];
      state.media.listMediaBuiltInHotspotLottie =
        action.payload.hotspotLotties || [];
    },
    [GET_LIST_MEDIA_BUILT_IN.FAILURE]: state => {},

    /**
     * !GET LIST ALBUM
     */
    [GET_LIST_ALBUM.TRIGGER]: (
      state,
      action: PayloadAction<I_OptionsGetListMedia>,
    ) => {},
    [GET_LIST_ALBUM.SUCCESS]: (state, action: PayloadAction<I_Album[]>) => {
      state.media.listAlbum = [...state.media.listAlbum, ...action.payload];
    },
    [GET_LIST_ALBUM.FAILURE]: state => {},

    /**
     * ! DELETES OR MOVES MEDIA
     */
    [DELETES_OR_MOVES_MEDIA.TRIGGER]: (
      state,
      action: PayloadAction<I_PayloadDeletesOrMovesMedia>,
    ) => {
      state.media.loadingDeletesOrMoves = 1;
      state.media.successDeletesOrMoves = false;
    },
    [DELETES_OR_MOVES_MEDIA.SUCCESS]: (
      state,
      action: PayloadAction<{ type: 'move' | 'delete'; ids: string[] }>,
    ) => {
      state.media.loadingDeletesOrMoves = 0;
      state.media.successDeletesOrMoves = true;

      if (action.payload.type === 'delete' && state.infoUser) {
        let newSize = state.infoUser.size || 0;
        state.media.listMedia
          .filter(item => action.payload.ids.indexOf(item.id) > -1)
          .forEach(item => {
            newSize = calcStorage(newSize, 'delete', item.size, item.type);
          });
        state.infoUser = {
          ...state.infoUser,
          ...{ size: newSize },
        };
      }

      state.media.listMedia = state.media.listMedia.filter(
        item => action.payload.ids.indexOf(item.id) === -1,
      );
      const totalDocs = state.media.totalDocMedia - action.payload.ids.length;
      state.media.totalDocMedia = totalDocs;
      state.media.totalPageMedia = Math.ceil(totalDocs / 20);
    },
    [DELETES_OR_MOVES_MEDIA.FAILURE]: state => {
      state.media.loadingDeletesOrMoves = 0;
      state.media.successDeletesOrMoves = false;
    },

    /**
     * !UPLOAD MEDIA
     */
    [UPLOAD_MEDIA.TRIGGER]: (
      state,
      action: PayloadAction<I_OptionUploadMedia>,
    ) => {
      state.media.loading++;
      state.media.success = false;
      state.media.error = false;
    },
    [UPLOAD_MEDIA.SUCCESS]: (
      state,
      action: PayloadAction<I_Media & { folder?: string }>,
    ) => {
      if (action.payload && state.infoUser) {
        let newSize = calcStorage(
          state.infoUser.size || 0,
          'add',
          action.payload.size,
          action.payload.type,
        );
        state.infoUser = {
          ...state.infoUser,
          ...{ size: newSize },
        };
      }

      if (action.payload?.folder === state.media.optionsGetListMedia.folder) {
        // NOTE: remove last item to avoid error duplicate item when load next page
        state.media.listMedia.length > 20 && state.media.listMedia.pop();
        state.media.listMedia = [action.payload, ...state.media.listMedia];

        const totalDocs = state.media.totalDocMedia + 1;
        state.media.totalDocMedia = totalDocs;
        state.media.totalPageMedia = Math.ceil(totalDocs / 20);
      }
      state.media.loading--;
      state.media.success = true;
      state.media.error = false;
    },
    [UPLOAD_MEDIA.FAILURE]: state => {
      state.media.loading--;
      state.media.success = false;
      state.media.error = true;
    },
    /**
     * GET AWS
     */
    [GET_AWS.TRIGGER]: state => {},
    [GET_AWS.SUCCESS]: (state, action: PayloadAction<I_AwsConfig>) => {
      state.aws = action.payload;
    },
    [GET_AWS.FAILURE]: state => {},

    /**
     * !GET ME
     */
    [GET_ME.TRIGGER]: state => {
      state.loading = true;
      // state.success = false;
      state.error = false;
    },
    [GET_ME.SUCCESS]: (
      state,
      action: PayloadAction<{
        data: I_User;
        subscription?: I_SubscriptionInfo;
        feature: string[];
      }>,
    ) => {
      state.infoUser = action.payload.data;
      state.subscriptionInfo = action.payload.subscription || undefined;
      state.featureRequestVoted = action.payload.feature || [];
      state.loading = false;
      // state.success = true;
      state.error = false;
    },
    [GET_ME.FAILURE]: state => {
      state.loading = false;
      // state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = appSlice;
