import React, { memo, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { GlobalStyle } from 'styles/global-styles';
import CheckNewVersion from './components/CheckNewVersion';
import Router from './components/Router';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  GET_ME,
  reducer,
  actions,
  sliceKey,
  GET_LIST_ALBUM,
  GET_LIST_MEDIA,
  GET_LIST_MEDIA_BUILT_IN,
} from './slice';
import { appSaga } from './saga';
import {
  reducer as profileReducer,
  sliceKey as profileSliceKey,
} from 'app/containers/Profile/slice';
import {
  GET_LIST_ALL_PROJECT,
  reducer as projectReducer,
  sliceKey as projectSliceKey,
} from 'app/containers/Dashboard/ProjectManagement/slice';
import { profileSaga } from 'app/containers/Profile/saga';
import { projectManagementSaga } from 'app/containers/Dashboard/ProjectManagement/saga';
import { AppWrapper } from './styled';
import { auth, cleanObject } from 'utils/helper';
import { initPusher, destroyPusher } from 'app/services/pusher';

import { OverlayThumb } from 'app/components/OverlayThumb/Loadable';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsLogged,
  selectOptionsGetMedia,
  selectOptionsGetAlbum,
  selectNotifications,
  // selectInfoUser,
} from './selectors';
import NotificationsSystem, {
  atalhoTheme,
  setUpNotifications,
  dismissNotification,
} from 'reapop';
import { TYPE_COOKIE, TYPE_LOCAL_STORAGE } from 'utils/constants';
import { useHotspotStats } from 'app/containers/Editors/TourEditor/global/HotspotGlobal';
// import { useStripe } from 'utils/hooks/useStripe';
import { GET_CARDS } from '../Profile/slice';
import { PAYLOAD_GET_LIST_ALL } from './constant';
import { updateVisitorAffiliateLinkApi } from 'app/services/affiliateApi';
import { setCookie } from 'utils/cookies';

// run this function when your application starts before creating any notifications
setUpNotifications({
  defaultProps: {
    // image: Logo,
    allowHTML: true,
    dismissible: true,
    dismissAfter: 2000,
    transition: 'slide',
    position: 'bottom-right',
    showDismissButton: true,
  },
});

interface Props {}

export const App = memo((props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: appSaga });
  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });
  useInjectSaga({ key: profileSliceKey, saga: profileSaga });
  useInjectReducer({ key: projectSliceKey, reducer: projectReducer });
  useInjectSaga({ key: projectSliceKey, saga: projectManagementSaga });

  const dispatch = useDispatch();
  const [, setLastHotspotConfig] = useHotspotStats('lastHotspotConfig');

  // const infoUser = useSelector(selectInfoUser);
  const isLogged = useSelector(selectIsLogged);
  const notifications = useSelector(selectNotifications);
  const optionsGetListMedia = useSelector(selectOptionsGetMedia);
  const optionsGetListAlbum = useSelector(selectOptionsGetAlbum);

  // const { onConfigStripe } = useStripe();

  const payloadGetListMedia = useMemo(() => cleanObject(optionsGetListMedia), [
    optionsGetListMedia,
  ]);

  useEffect(() => {
    if (isLogged) {
      dispatch(GET_LIST_ALBUM(optionsGetListAlbum));
      dispatch(GET_LIST_MEDIA_BUILT_IN());
      dispatch(GET_LIST_ALL_PROJECT(PAYLOAD_GET_LIST_ALL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, optionsGetListAlbum]);

  useEffect(() => {
    if (isLogged) dispatch(GET_LIST_MEDIA(payloadGetListMedia));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadGetListMedia, isLogged]);

  /**
   * NOTE: check & save affiliateId
   */
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const affiliateId = search.get('ref');
    if (affiliateId) {
      setCookie(TYPE_COOKIE.AFFILIATE_ID, affiliateId);
      updateVisitorAffiliateLinkApi({ affiliateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lastHotspotConfig = localStorage.getItem(
      TYPE_LOCAL_STORAGE.LAST_CONFIG_HOTSPOT,
    );
    if (lastHotspotConfig) setLastHotspotConfig(JSON.parse(lastHotspotConfig));
    dispatch(actions.changeIsLogged({ isLogged: auth() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) {
      initPusher();
      dispatch(GET_ME());
      // dispatch(GET_AWS());
      // dispatch(GET_CARDS());
    } else destroyPusher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  // useEffect(() => {
  //   if (infoUser)
  //     addScriptDynamic('https://checkout.stripe.com/checkout.js', () => {
  //       onConfigStripe();
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [infoUser]);

  useEffect(
    () => () => {
      dispatch(actions.resetState());
    },
    [dispatch],
  );

  return (
    <>
      <Helmet
        titleTemplate="%s - Panoee - The Smart Virtual Tour Software for everyone"
        defaultTitle="Panoee | The Smart Virtual Tour Software for everyone"
      >
        <meta
          name="description"
          content="Start free to build high-quanlity &amp; smart 360° Virtual Tour in minutes with beautiful themes &amp; unlimited customization."
        />
      </Helmet>
      <CheckNewVersion>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading)
            return (
              <OverlayThumb icon={<LoadingOutlined />} label="Loading..." />
            );
          if (!loading && !isLatestVersion) refreshCacheAndReload();
          return (
            <AppWrapper>
              <Router key={`${isLogged}`} />
            </AppWrapper>
          );
        }}
      </CheckNewVersion>
      <GlobalStyle />
      <NotificationsSystem
        theme={atalhoTheme}
        notifications={notifications}
        dismissNotification={id => dispatch(dismissNotification(id))}
      />
    </>
  );
});
