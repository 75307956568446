import styled, { createGlobalStyle, css } from 'styled-components';
import { StyleConstants } from './StyleConstants';

export const Flex = css`
  display: flex;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexRowCenter = css`
  ${Flex};
  justify-content: center;
  align-items: center;
`;

export const FlexRowStart = css`
  ${Flex};
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowEnd = css`
  ${Flex};
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowBetween = css`
  ${Flex}
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowAround = css`
  ${Flex}
  justify-content: space-around;
  align-items: center;
`;

export const FlexColCenter = css`
  ${FlexCol};
  ${FlexRowCenter}
`;

export const FlexColStart = css`
  ${FlexCol};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexColEnd = css`
  ${FlexCol};
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FlexColBetween = css`
  ${FlexCol};
  justify-content: space-between;
  align-items: baseline;
`;

export const BackgroundCover = css<{ bgUrl: string | null | undefined }>`
  background-image: url(${props => props.bgUrl && props.bgUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackgroundContain = css`
  ${BackgroundCover};
  background-size: contain;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${FlexRowCenter};
`;

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  :root {
    --color-theme: #D1005C;
    --color-green: #2EC26A;
    --color-blue: #007AFF;
    --color-yellow: #F0C330;
    --color-red: #E54D42;
    --color-smoke: #969FA7;
    --color-dark: #212121;
    --color-dark-2: #393839;
    --color-dark-3: #191919;
    --color-light: #E3E8EE;
    --transition-smooth: all 0.5s cubic-bezier(0.04, 1, 0.6, 0.97);
    --transition-smooth-2: all 1.5s cubic-bezier(0.04, 1, 0.6, 0.97);
    --fontSize-h1: 32px;
    --fontSize-h2: 28px;
    --fontSize-h3: 24px;
    --fontSize-h4: 20px;
    --fontSize-h5: 16px;
  }
  * {
    /* user-select: none; */
  }
  input {
    user-select: initial;
  }
  small {
    font-size: 12px;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    color: #fff;
    background: var(--color-dark);
    font-size: 16px;
    line-height: 1.5;
    /* padding-top: ${StyleConstants.NAV_BAR_HEIGHT}; */
  }

  body.fontLoaded {
    font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", Helvetica, Arial, sans-serif;
  }
  h1, .h1 {
    font-size: var(--fontSize-h1);
  }
  h2, .h2 {
    font-size: var(--fontSize-h2);
  }
  h3, .h3 {
    font-size: var(--fontSize-h3);
  }
  h4, .h4 {
    font-size: var(--fontSize-h4);
  }
  h5, .h5 {
    font-size: var(--fontSize-h5);
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  ui, li {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  abbr[title] {
    text-decoration: none;
  }

  .fullWidth {
    width: 100% !important;
  }

  .fullHeight {
    height: 100% !important;
  }

  .action__btn {
    i {
      margin-right: 0.5rem;
    }
  }

  .ant-form-item {
    .ant-input {
      background-color: rgb(0 0 0 / .35) !important;
      border: 1px solid rgb(255 255 255 / .15);
      color: #fff;
      box-shadow: none!important;
      &:hover {
        border-color: rgb(255 255 255 / .5);
      }
      &:focus {
        border-color: #fff;
        background-color: rgb(0 0 0 / .5);
      }
    }
  }

  /* .icon {
    width: 1.5rem;
    height: 1.5rem;
  } */

  #root {
    width: 100%;
    height: 100%;
  }

  .cursorPointer {
    cursor: pointer !important;
  }

  [class*='ant']  {
    animation-duration: 0s !important;
  }

  .anticon-spin, .ant-spin-dot-spin {
    animation-duration: 1s !important;
  }

  .tablet-none {
    @media (max-width: 1025px) {
      display: none!important;
    }
  }

  .mobile-none {
    @media (max-width: 575px) {
      display: none!important;
    }
  }

  .ant-table {
    &-body {
      &::-webkit-scrollbar {
        background: rgb(56 56 56);
        border-radius: 25px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        width: 80%;
        border-radius: 25px;
        border: 4px solid rgb(56 56 56);
      }
    }
    &-cell-scrollbar {
      box-shadow: none!important;
    }
  }

  body {
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
      border-right-color: unset;
    }

    .ant-slider {
      margin-left: 0;
      margin-right: .75rem;
      &-step {
        background: #4D4D4D;
      }
      &-track {
        background: var(--color-theme);
        z-index: 1;
      }
      &-handle {
        z-index: 2;
        border: 0;
        width: 12px;
        height: 12px;
        margin-top: -4px;
        border-radius: 50%;
        box-shadow: 0 2px 6px #000;
      }
    }

    .ant-picker-dropdown {
      border-radius: 8px;
      overflow: hidden;
      .ant-picker-panel {
        background: var(--color-dark-2);
        border: none;
      }
      .ant-picker-header {
        color: #fff;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        .ant-picker-header-view button {
          color: #fff;
        }
        button {
          color: var(--color-smoke);
        }
      }
      .ant-picker-body {
        color: #fff;
        .ant-picker-cell {
          color: var(--color-smoke);
          .ant-picker-cell-inner {
            border-radius: 4px;
          }
        }
        .ant-picker-cell-in-view {
          color: #fff;
          &:hover {
            .ant-picker-cell-inner {
              background: rgba(255,255,255,0.1)!important;
            }
          }
        }
        .ant-picker-cell-disabled {
          color: var(--color-smoke);
        }
      }
    }

    .ant-popover {
      padding: 0;
      background: transparent;
      &-message,
      &-buttons {
        padding: .5rem .75rem;
      }
      &-message {
        padding-bottom: 0;
        > .anticon {
          top: 14px;
        }
      }
      &-placement-top > .ant-popover-content > .ant-popover-arrow {
        bottom: 6.2px;
      }
      &-placement-bottom > .ant-popover-content > .ant-popover-arrow {
        top: 8px;
      }
      &-arrow {
        display: none;
      }
      &-inner {
        outline: none;
        border-radius: 6px;
        color: #fff;
        padding: 0;
        box-shadow: none;
        background: transparent;
        &-content {
          padding: 0;
        }
      }
    }

    .ant-badge-count {
      z-index: 3;
    }

    .ant-modal {
      padding-bottom: 0;
      border: none !important;
      &-close {
        display: none;
      }
      &-body {
        padding: 0;
        position: relative;
        /* overflow: hidden; */
      }
      &-content {
        background-color: var(--color-dark);
        border: 1px solid var(--color-dark-2);
        border-radius: 15px;
        overflow: hidden;
        color: #fff;
      }
      &-mask {
        background-color: rgba(0, 0, 0, 0.85);
      }
      &-confirm {
        &-body {}
        &-title {
          color: inherit!important;
        }
        &-content {
          color: var(--color-smoke)!important;
        }
        &-btns {
          .ant-btn {
            color: #fff;
            background: transparent;
            border-color: rgb(255 255 255 / .15);
          }
          .ant-btn-primary {
            background: var(--color-theme);
            border-color: transparent;
          }
        }
      }
    }

    .ant-btn > i, .ant-btn > span {
      display: inline-flex !important;
    }

    .ant-drawer {
      &-body {
        padding: 0;
      }
      &-close {
        display: none;
        z-index: -1;
      }
    }

    .ant-input-affix-wrapper > input.ant-input {
      &:hover, &:active, &:focus {
        border: none !important;
      }
      border: none !important;
    }

    .icon-font {
      max-width: 100%;
      font-size: 12px;
      i {
        font-size: 16px;
        margin-right: 5px;
      }
      ${FlexRowStart};
      &:first-child {
        margin-right: 4px;
      }
      &:not(:first-child) {
        margin-left: 4px;
      }
    }

    .anticon.ant-input-clear-icon {
      color: var(--color-smoke);
      &:hover {
        color: #fff;
      }
    }

    .ant-btn > .ant-btn-loading-icon .anticon {
      animation: loadingCircle 1s infinite linear;
    }


    .ant-col-0 {
      display: none !important;
    }

    .moveable-control {
      background: var(--color-theme) !important;
    }

    .sun-editor-editable .se-component {
      display: inline-block;
    }
  }


  .inline-flex {
    display: inline-flex !important;
    flex: 0 0 auto;
  }

  .hide {
    display: none !important;
  }

  .hide-scrollbar {
    &::-webkit-scrollbar {
      /* This is the magic bit for WebKit */
      display: none;
    }
  }

  .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  /* custom component */

  .itemProject {
    position: relative;
    &:hover {
      z-index: 2;
    }
  }

  .ant-pagination-options {
    .ant-select-selector {
      background: var(--color-dark-2);
      border: none;
      border-radius: 4px;
      color: #fff;
      box-shadow: none!important;
    }
  }

  .ant-tooltip {
    &-inner {
      background-color: var(--color-dark-2)!important;
      border-radius: 4px!important;
      padding: .5rem .75rem!important;
    }
    &-arrow {
      &-content {
        background-color: var(--color-dark-2)!important;
      }
    }
  }



  .ant-select {
    &-dropdown {
      border-radius: 4px!important;
      min-width: 140px!important;
      background: var(--color-dark)!important;
      border: 1px solid rgb(255 255 255 / .25)!important;
      .ant-empty-description {
        color: #fff;
      }
    }
    &-item {
      margin: 0!important;
      padding: .5rem!important;
      /* border-radius: 6px!important; */
      color: #fff!important;
      &-option-active {
        background-color: var(--color-dark-2)!important;
      }
      &-option-selected {
        background-color: var(--color-theme)!important;
      }
    }
    .ant-select-clear {
      color: var(--color-smoke);
      right: 26px;
      margin-top: -8px;
    }
    .ant-select-arrow {
      color: var(--color-smoke);
    }
  }
  .ant-checkbox {
    &-wrapper {

    }
    &-checked:after {
      border-radius: 50%!important;
    }
  }

  .ant-radio {
    &-inner {
      background-color: transparent !important;
    }
    &-inner:after {
      background: var(--color-blue);
      display: none !important;
    }
    &-checked .ant-radio-inner {
      border-color: var(--color-blue);
      border-width: 3px;
    }
  }

  .ant-cascader {
    &-menus {
      background: var(--color-dark)!important;
      border: 1px solid rgb(255 255 255 / .15);
      border-radius: 6px!important;
      overflow: hidden;
    }
    &-menu {
      border-right: 1px solid rgb(255 255 255 / .15)!important;
    }
    &-menu-item {
      &:hover {
        background: rgb(0 0 0 / .5)!important;
      }
      &-active {
        background-color: var(--color-blue)!important;
      }
    }
  }

  /* .ce-toolbar {
    &__plus {
      border: 1px solid #ddd;
      box-shadow: 0 1px 6px rgb(0 0 0 / .15);
      border-radius: 6px;
      width: 1.5rem;
      height: 1.5rem;
    }
    &__content {
      max-width: 508px;
    }
  }
  .ce-toolbox {
    left: .25rem;
  } */
  /* .cdx-list {
    &__item {
      list-style: initial;
      padding: .125rem 0 .125rem .25rem!important;
    }
  } */

  audio {
    height: 38px;
  }

  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0;
    margin-right: 8px;
  }

  .ant-tag {
    display: inline-flex !important;
    align-items: center !important;
  }
`;
