/**
 *
 * styled App
 *
 */
import styled from 'styled-components/macro';
import { InputWrapper } from 'app/components/Input/styled';
import { SceneThumbWrapper } from 'app/components/SceneThumb/styled';
import { FlexColStart, FlexRowBetween } from 'styles/global-styles';

export const AppWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  font-size: 14px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const PanelConfigGlobal = styled.div`
  ${InputWrapper} {
    width: 100%;
  }
  .ant-select {
    &-single:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 6px;
    }
    &-arrow {
      color: rgba(255, 255, 255, 0.5);
    }
    &-clear {
      background: transparent;
      &:hover {
        color: #fff;
      }
    }
  }

  .bg-sound {
    &__config {
      ${FlexColStart};
      width: 100%;
    }
    &__preview {
      margin-bottom: 5px;
      width: 100%;
      audio {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.5);
        overflow: hidden;
        outline: none;
        border-radius: 32px;
      }
    }
    &__action {
      ${FlexRowBetween};
    }
    &__btn {
      color: #fff;
      font-size: 0.75rem;
      padding: 0 0.75rem;
      border: none;
      border-radius: 5px;
      transition: var(--transition-smooth);
      &.--danger {
        background: var(--color-red);
        margin-left: 5px;
      }
      &.--primary,
      &.--primary[disabled] {
        background: var(--color-blue);
        color: #fff;
      }
    }
  }

  .input {
    width: 100%;
    .ant-input-disabled {
      background: rgba(255, 255, 255, 0.15);
      color: #fff;
    }
    .ant-input-password {
      background: rgba(0, 0, 0, 0.34);
      border: 1px solid rgba(255, 255, 255, 0.15);
      font-size: 14px;
      input {
        color: #969fa7;
        background: rgba(0, 0, 0, 0.34);
      }
      .ant-input-password-icon {
        color: #969fa7;
        &:hover {
          color: #969fa7;
        }
      }
    }
  }
  .checkbox {
    .ant-switch {
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.25);
      height: 24px;
      color: #fff;
      &-handle {
        top: 2px;
        left: 2px;
      }
      &-checked {
        .ant-switch-handle {
          left: calc(100% - 18px - 2px);
          &:before {
            background-color: var(--color-blue);
          }
        }
      }
    }
    .ant-radio-wrapper {
      color: #fff;
    }
  }
  .action {
    padding-left: 5px;
    &__btn {
      color: #fff;
      font-size: 0.75rem;
      padding: 0 0.75rem;
      border: none;
      border-radius: 5px;
      background: var(--color-blue);
      transition: var(--transition-smooth);
      &:hover {
        transform: scale(0.95);
      }
      &.btn__reset {
        margin-left: 5px;
        color: #fefefe;
        border: 1px solid rgb(255 255 255 / 0.25);
        background: var(--color-dark);
      }
    }
  }
  .thumb {
    width: 80px;
    flex: 0 0 auto;
    margin-right: 5px;
    ${SceneThumbWrapper} {
      width: 100%;
      margin: 0;
      &:before {
        display: none;
      }
    }
  }
  .select {
    width: 100%;
    .ant-select {
      width: 100%;
    }
  }
`;

export const TabContentGlobal = styled.div`
  padding: 0;
  color: #fff;
  overflow: auto;
  height: 100%;
  visibility: visible;
  z-index: 1;
`;

export const AsideNextMenuGlobal = styled.div`
  height: 100%;
  width: 100%;
  max-width: 350px;
  background: var(--color-dark);
`;

export const GlobalConfigWrapper = styled.div`
  .--icon {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 0px;
    text-align: center;
    font-size: 18px;
    border-radius: 0px 6px 6px 0 !important;
    z-index: 0;
    pointer-events: auto;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8%;
    background-color: var(--color-theme);
    z-index: 100;
  }
  .--option {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    padding: 12px 0;
    .--label {
    }
    .--value {
      float: right;
    }
  }
`;
