// import { $get } from 'utils/axios';
import {
  take,
  select,
  fork,
  takeLatest,
  takeEvery,
  all,
  call,
  put,
} from 'redux-saga/effects';
import {
  callApiGetListMedia,
  deletesOrMovesMediaApi,
  getListMediaBuiltInApi,
  uploadMedia,
} from 'app/services/mediaApi';
import {
  actions,
  DELETES_OR_MOVES_MEDIA,
  GET_AWS,
  GET_LIST_ALBUM,
  GET_LIST_MEDIA,
  GET_LIST_MEDIA_BUILT_IN,
  GET_ME,
  UPLOAD_MEDIA,
} from './slice';
import {
  selectAlbumSelected,
  selectTotalDocMedia,
  selectTotalPageMedia,
} from './selectors';
import { LOGIN } from '../Account/slice';
import { callApiGetProfile, getAWSKeyApi } from 'app/services/authenApi';
import { history } from 'utils/history';
import { store } from 'index';
import { getCookie, removeCookie } from 'utils/cookies';
import { TYPE_COOKIE } from 'utils/constants';
import { I_MediaUploading } from './types';
import { generateRandomID } from 'utils/helper';

export function* watchAndLog() {
  while (true) {
    const styleConsole =
      'background: yellow; font-weight: bold; color: #40a9ff8c;';
    const action = yield take('*');
    const state = yield select();
    if (
      action.type !== '@@router/LOCATION_CHANGE' &&
      action.type !== 'app/updateMediaUploadingProgress' &&
      action.type !== 'reapop/upsertNotification' &&
      action.type !== 'reapop/dismissNotification'
    ) {
      console.group(action.type);
      console.log('%cBefore State', styleConsole, state);
      console.info('%cDispatching', styleConsole, action);
      console.log('%cNext State', styleConsole, state);
      console.groupEnd();
    }
  }
}

function* uploadImage({ payload }) {
  const id: string = yield generateRandomID();
  const albumSelected = yield select(selectAlbumSelected);
  const itemUploading: I_MediaUploading = yield {
    id,
    progress: 0,
    folder: albumSelected?.id || '',
  };
  try {
    yield put(actions.addMediaUploadingProgress(itemUploading));
    const response = yield call(uploadMedia, payload, {
      onUploadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length',
            );
        if (totalLength !== null) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / totalLength,
          );
          store.dispatch(
            actions.updateMediaUploadingProgress({
              id,
              progress,
              folder: albumSelected?.id || '',
            }),
          );
        }
      },
      timeout: 10 * 60 * 1000,
    });
    const { data } = response;
    yield put(actions.removeMediaUploadingProgress(id));
    yield put(
      UPLOAD_MEDIA.success({
        ...data.data,
        folder: albumSelected?.id || '',
      }),
    );
  } catch (err) {
    yield put(actions.removeMediaUploadingProgress(id));
    yield put(UPLOAD_MEDIA.failure());
  }
}

function* getListMedia({ payload }) {
  try {
    const totalPages = yield select(selectTotalPageMedia);
    const totalDocs = yield select(selectTotalDocMedia);
    const response = yield call(callApiGetListMedia, payload);
    yield put(GET_LIST_MEDIA.success(response.data.data.docs));
    if (
      totalPages !== response.data.data.totalPages ||
      totalDocs !== response.data.data.totalDocs
    )
      yield put(
        actions.setOptionPaginationMedia({
          totalPageMedia: response.data.data.totalPages,
          totalDocMedia: response.data.data.totalDocs,
        }),
      );
  } catch (err) {
    yield put(GET_LIST_MEDIA.failure());
  }
}

function* deletesOrMovesMedia({ payload }) {
  try {
    const { data } = yield call(deletesOrMovesMediaApi, payload);
    if (data?.msg)
      yield put(
        DELETES_OR_MOVES_MEDIA.success({
          ids: payload.ids,
          type: payload.type,
        }),
      );
  } catch (err) {
    yield put(DELETES_OR_MOVES_MEDIA.failure());
  }
}

function* getListMediaBuiltIn() {
  try {
    const payload = { page: 1, limit: 10000 };
    const [
      { data: hotspotImages },
      { data: hotspotLotties },
      { data: hotspotMarkers },
    ] = yield all([
      call(getListMediaBuiltInApi, {
        ...payload,
        type: 'hotspot-image',
      }),
      call(getListMediaBuiltInApi, {
        ...payload,
        type: 'hotspot-lottie',
      }),
      call(getListMediaBuiltInApi, {
        ...payload,
        type: 'hotspot-marker',
      }),
    ]);

    yield put(
      GET_LIST_MEDIA_BUILT_IN.success({
        hotspotImages: hotspotImages?.data?.docs || [],
        hotspotLotties: hotspotLotties?.data?.docs || [],
        hotspotMarkers: hotspotMarkers?.data?.docs || [],
      }),
    );
  } catch (err) {
    yield put(GET_LIST_MEDIA_BUILT_IN.failure());
  }
}

function* getListAlbum({ payload }) {
  try {
    const response = yield call(callApiGetListMedia, payload);
    yield put(GET_LIST_ALBUM.success(response.data.data.docs));
  } catch (err) {
    yield put(GET_LIST_ALBUM.failure());
  }
}

export function* getAwsConfig() {
  try {
    const response = yield call(getAWSKeyApi);
    const data = { ...response.data.data };
    yield put(GET_AWS.success(data));
  } catch {
    yield put(GET_AWS.failure());
  }
}

function* watchIsLogged() {
  while (true) {
    yield take(LOGIN.SUCCESS);
    yield put(actions.changeIsLogged({ isLogged: true }));
  }
}

export function* getMe() {
  try {
    const affiliateId = yield getCookie(TYPE_COOKIE.AFFILIATE_ID);
    const response = yield call(callApiGetProfile, { affiliateId });
    if (affiliateId) yield removeCookie(TYPE_COOKIE.AFFILIATE_ID);
    yield put(GET_ME.success(response.data));
  } catch {
    yield put(GET_ME.failure());
  }
}

function* logout() {
  yield put(actions.changeIsLogged({ isLogged: false }));
  yield put(actions.resetMedia());
  yield history.push('/account/auth');
}

export function* appSaga() {
  yield fork(watchIsLogged);
  yield takeLatest(GET_ME.TRIGGER, getMe);
  yield takeLatest(actions.logout.type, logout);
  yield takeLatest(GET_AWS.TRIGGER, getAwsConfig);
  yield takeLatest(GET_LIST_ALBUM.TRIGGER, getListAlbum);
  yield takeLatest(DELETES_OR_MOVES_MEDIA.TRIGGER, deletesOrMovesMedia);
  yield takeLatest(GET_LIST_MEDIA_BUILT_IN.TRIGGER, getListMediaBuiltIn);
  yield takeEvery(GET_LIST_MEDIA.TRIGGER, getListMedia);
  yield all([takeEvery(UPLOAD_MEDIA.TRIGGER, uploadImage)]);
  if (process.env.NODE_ENV === 'development') yield fork(watchAndLog);
}
