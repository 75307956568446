import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';
import { auth } from 'utils/helper';
import { history } from 'utils/history';
import { useMobileDetect } from 'utils/hooks/useDetectMobile';
import { setItem } from 'utils/localStorage';
import { LIST_PUBLIC_ROUTE } from './constants';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { checkDevice } = useMobileDetect();
  const { path } = rest;
  return (
    <Route
      {...rest}
      render={props => {
        if (auth()) {
          if (checkDevice().isMobile()) {
            if (path.includes('recommend-use-pc')) {
              return <Component {...props} />;
            }
            return (
              <Redirect
                to={{
                  pathname: '/recommend-use-pc',
                }}
              />
            );
          } else {
            if (path.includes('recommend-use-pc')) {
              return (
                <Redirect
                  to={{
                    pathname: '/',
                  }}
                />
              );
            }
            return <Component {...props} />;
          }
        } else {
          let pathname = history.location.pathname;
          if (!LIST_PUBLIC_ROUTE.some(item => pathname.includes(item)))
            setItem(TYPE_LOCAL_STORAGE.URL_REDIRECT, pathname);
          return (
            <Redirect
              to={{
                pathname: '/account/auth',
              }}
            />
          );
        }
      }}
    />
  );
};
