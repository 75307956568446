import {
  I_PayloadCreateProject,
  I_Project,
} from 'app/containers/Dashboard/ProjectManagement/types';
import { I_OptionDelete } from 'types';
import { BaseXHR } from 'utils/axios';

export const callApiGetListProjects = params =>
  BaseXHR.$get('project/gets', params);

export const callApiCreateNewProject = (payload: I_PayloadCreateProject) =>
  BaseXHR.$post('project/create', payload);

export const updateProjectApi = (payload: Partial<I_Project>) =>
  BaseXHR.$put('project/update', payload);

export const callApiDeleteProject = (payload: I_OptionDelete) =>
  BaseXHR.$delete('project/delete', payload);

export const callApiGetProjectById = (id: string) =>
  BaseXHR.$get(`project/get?id=${id}`);

export const generateFeatureImageProjectApi = payload =>
  BaseXHR.$put('project/renderFeatured', payload);

export const getStatusVerifyDomainApi = payload =>
  BaseXHR.$get('project/verify-domain', payload);

export const addDomainToALBApi = payload =>
  BaseXHR.$put('project/verify-domain', payload);

export const paymentForPlusFeatureProjectApi = payload =>
  BaseXHR.$post('project/payment-api', payload);

export const paidOffForPlusFeatureProjectApi = params =>
  BaseXHR.$get('project/return-payment-api' + params);

export const setFreeProjectApi = payload =>
  BaseXHR.$put('account/free-project', payload);
