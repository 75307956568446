import { createRoutine } from 'redux-saga-routines';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  I_Group,
  I_MediaScene,
  I_OptionsGetListScene,
  I_Scene,
  I_OptionsGetListGroup,
  I_PayloadUpdateGroup,
  I_PayloadCreateGroup,
  I_PayloadSortGroup,
} from './types';
import { I_OptionDelete } from 'types/customType';
import { I_Project } from '../Dashboard/ProjectManagement/types';

export const GET_LIST_SCENE = createRoutine('editors/GetListScene');
export const GET_DETAIL_PROJECT = createRoutine('editors/getDetailProject');

export const GET_LIST_GROUP = createRoutine('editors/GetListGroup');
export const CREATE_GROUP = createRoutine('editors/createGroup');
export const SORT_GROUP = createRoutine('editors/sortGroup');
export const UPDATE_GROUP = createRoutine('editors/updateGroup');
export const DELETE_GROUP = createRoutine('editors/deleteGroup');

// The initial state of the Editors container
export const initialState: ContainerState = {
  loading: 0,
  success: false,
  error: false,
  detailProject: undefined,
  group: {
    loading: 0,
    success: false,
    error: false,
    groups: [],
  },
  scene: {
    loading: 0,
    success: false,
    error: false,
    listScene: [],
  },
};

const editorsSlice = createSlice({
  name: 'editors',
  initialState,
  reducers: {
    /**
     * !UPDATE detail project
     *  */
    updateProjectDetail(state, action: PayloadAction<I_Project>) {
      state.detailProject = { ...state.detailProject, ...action.payload };
    },

    /**
     * ! GROUP
     */

    setListGroup(state, action: PayloadAction<I_Group[]>) {
      state.group.groups = action.payload;
    },
    updateOrderListGroup(
      state,
      action: PayloadAction<{ listGroup: I_Group[] }>,
    ) {
      const { listGroup } = action.payload;
      state.group.groups = listGroup;
    },
    updateGroup(state, action: PayloadAction<I_Group>) {
      state.group.groups = state.group.groups.map(item =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item,
      );
    },
    addGroup(state, action: PayloadAction<I_Group>) {
      state.group.groups = [
        { ...action.payload, isJustCreated: true },
        ...state.group.groups.map(item =>
          item.isJustCreated ? { ...item, isJustCreated: false } : item,
        ),
      ];
    },
    deleteGroup(state, action: PayloadAction<string>) {
      state.group.groups = state.group.groups.filter(
        item => item.id !== action.payload,
      );
      state.scene.listScene = state.scene.listScene.map(item =>
        item.group === action.payload ? { ...item, group: null } : item,
      );
    },

    /**
     * ! UPDATE LIST SCENE
     */
    addScene(state, action: PayloadAction<I_Scene>) {
      state.scene.listScene = [action.payload, ...state.scene.listScene];
    },
    updateScene(state, action: PayloadAction<I_Scene>) {
      let { sort, ...payload } = action.payload;
      state.scene.listScene = state.scene.listScene.map(item =>
        item.id === payload.id ? { ...item, ...payload } : item,
      );
    },
    removeScene(state, action: PayloadAction<I_OptionDelete>) {
      const listSceneNewest = state.scene.listScene.filter(
        item => item.id !== action.payload.id,
      );
      state.scene.listScene = [...listSceneNewest];
    },
    updateOrderListScene(
      state,
      action: PayloadAction<{ listScene: I_Scene[] }>,
    ) {
      const { listScene } = action.payload;
      state.scene.listScene = listScene;
    },

    /**
     * ! UPDATE media of scene via SOCKET events
     */
    updateMediaInScenes(state, action: PayloadAction<Partial<I_MediaScene>>) {
      state.scene.listScene = state.scene.listScene.map(item =>
        item.media && item.media.id === action.payload.id
          ? { ...item, ...{ media: { ...item.media, ...action.payload } } }
          : item,
      );
    },

    /**
     * ! RESET state
     */
    reset(state) {
      Object.keys(state).forEach(item => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers: {
    /**
     * !GET PROJECT
     */
    [GET_DETAIL_PROJECT.TRIGGER]: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.loading++;
      state.success = false;
      state.error = false;
    },
    [GET_DETAIL_PROJECT.SUCCESS]: (state, action: PayloadAction<I_Project>) => {
      state.detailProject = action.payload;
      state.loading--;
      state.success = true;
      state.error = false;
    },
    [GET_DETAIL_PROJECT.FAILURE]: state => {
      state.loading = 0;
      state.success = false;
      state.error = true;
    },

    /**
     * ! GET_LIST_GROUP
     */
    [GET_LIST_GROUP.TRIGGER]: (
      state,
      action: PayloadAction<I_OptionsGetListGroup>,
    ) => {
      state.group.loading++;
      // state.group.success = false;
      state.group.error = false;
    },
    [GET_LIST_GROUP.SUCCESS]: (state, action: PayloadAction<I_Group[]>) => {
      state.group.groups = action.payload;
      state.group.loading--;
      // state.group.success = true;
      state.group.error = false;
    },
    [GET_LIST_GROUP.FAILURE]: state => {
      state.group.loading = 0;
      // state.group.success = false;
      state.group.error = true;
    },
    /**
     * ! CREATE_GROUP
     */
    [CREATE_GROUP.TRIGGER]: (
      state,
      action: PayloadAction<I_PayloadCreateGroup>,
    ) => {
      state.group.loading++;
      state.group.success = false;
      state.group.error = false;
    },
    [CREATE_GROUP.SUCCESS]: (state, action: PayloadAction<I_Group>) => {
      // NOTE: add property isJustCreated to open edited mode on listGroup
      state.group.groups = [
        { ...action.payload, isJustCreated: true },
        ...state.group.groups.map(item =>
          item.isJustCreated ? { ...item, isJustCreated: false } : item,
        ),
      ];
      state.group.loading--;
      state.group.success = true;
      state.group.error = false;
    },
    [CREATE_GROUP.FAILURE]: state => {
      state.group.loading = 0;
      state.group.success = false;
      state.group.error = true;
    },
    /**
     * ! UPDATE_GROUP
     */
    [UPDATE_GROUP.TRIGGER]: (
      state,
      action: PayloadAction<I_PayloadUpdateGroup>,
    ) => {
      state.group.loading++;
      state.group.success = false;
      state.group.error = false;
    },
    [UPDATE_GROUP.SUCCESS]: (state, action: PayloadAction<I_Group>) => {
      state.group.groups = state.group.groups.map(item =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item,
      );
      state.group.loading--;
      state.group.success = true;
      state.group.error = false;
    },
    [UPDATE_GROUP.FAILURE]: state => {
      state.group.loading = 0;
      state.group.success = false;
      state.group.error = true;
    },
    /**
     * ! SORT_GROUP (run background)
     */
    [SORT_GROUP.TRIGGER]: (
      state,
      action: PayloadAction<I_PayloadSortGroup>,
    ) => {},
    [SORT_GROUP.SUCCESS]: (state, action: any) => {},
    [SORT_GROUP.FAILURE]: state => {},
    /**
     * ! DELETE_GROUP
     */
    [DELETE_GROUP.TRIGGER]: (state, action: PayloadAction<I_OptionDelete>) => {
      state.group.loading++;
      // state.group.success = false;
      state.group.error = false;
    },
    [DELETE_GROUP.SUCCESS]: (state, action: PayloadAction<string>) => {
      state.group.groups = state.group.groups.filter(
        item => item.id !== action.payload,
      );
      state.scene.listScene = state.scene.listScene.map(item =>
        item.group === action.payload ? { ...item, group: null } : item,
      );
      state.group.loading--;
      // state.group.success = true;
      state.group.error = false;
    },
    [DELETE_GROUP.FAILURE]: state => {
      state.group.loading = 0;
      // state.group.success = false;
      state.group.error = true;
    },

    /**
     * ! GET_LIST_SCENE
     */
    [GET_LIST_SCENE.TRIGGER]: (
      state,
      action: PayloadAction<I_OptionsGetListScene>,
    ) => {
      state.scene.loading++;
      state.scene.success = false;
      state.scene.error = false;
    },
    [GET_LIST_SCENE.SUCCESS]: (state, action: PayloadAction<I_Scene[]>) => {
      state.scene.listScene = action.payload;
      state.scene.loading--;
      state.scene.success = true;
      state.scene.error = false;
    },
    [GET_LIST_SCENE.FAILURE]: state => {
      state.scene.loading = 0;
      state.scene.success = false;
      state.scene.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = editorsSlice;
