import { BaseXHR } from 'utils/axios';

export const callApiLogin = payload => BaseXHR.$post('auth/login', payload);

export const callApiLoginSocial = payload =>
  BaseXHR.$post('auth/login-social', payload);

export const callApiRegister = payload =>
  BaseXHR.$post('auth/register', payload);

export const callApiRegisterFb = payload =>
  BaseXHR.$post('auth/register-fb', payload);

export const callApiRegisterGg = payload =>
  BaseXHR.$post('auth/register-gg', payload);

export const callApiVerifyAccount = payload =>
  BaseXHR.$put('auth/active-code', payload);

export const callApiForgotPassword = payload =>
  BaseXHR.$put('auth/forgot-password', payload);

export const callApiResetPassword = payload =>
  BaseXHR.$put('auth/change-forgot-password', payload);

export const callApiGetProfile = payload =>
  BaseXHR.$get('account/profile', payload);

export const getAWSKeyApi = () => BaseXHR.$get('account/aws');
