import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';
import { auth } from 'utils/helper';
import { getItem, removeItem } from 'utils/localStorage';

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth()) {
          return <Component {...props} />;
        } else {
          const url_redirect = getItem(TYPE_LOCAL_STORAGE.URL_REDIRECT);
          removeItem(TYPE_LOCAL_STORAGE.URL_REDIRECT);
          return (
            <Redirect
              to={{
                pathname: url_redirect || '/',
              }}
            />
          );
        }
      }}
    />
  );
};
