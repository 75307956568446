/**
 *
 * styled SceneThumb
 *
 */
import styled, { css } from 'styled-components/macro';
import { FlexRowCenter } from 'styles/global-styles';
import { OverlayThumbWrapper } from '../OverlayThumb/styled';

export const SceneThumbWrapper = styled.div<{
  minHeight: string;
  opacity: number;
  active: boolean;
  isFullWidth?: boolean;
}>`
  position: relative;
  cursor: pointer;
  width: ${props =>
    props.isFullWidth ? 'calc(100% - 4px)' : 'calc(100% - 1.5rem)'};
  height: 100%;
  min-height: ${props => props.minHeight};
  opacity: ${props => props.opacity};
  background-size: auto 110%;
  background-position: bottom center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 6px;
  margin: 0.25rem 0.75rem 4px;
  overflow: hidden;
  /* cursor: pointer; */
  transition: all 0.15s ease-out;
  box-shadow: inset 0 0 8px rgb(255 255 255 / 0.25);
  ${props =>
    props.active &&
    css`
      box-shadow: 0 0 0 2px var(--color-theme), 0 0 0 4px rgb(209 1 92 / 0.25) !important;
    `}
  &:hover {
    box-shadow: 0 0 0 2px rgb(255 255 255 / 1);
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    background: linear-gradient(0deg, black, transparent);
    z-index: 1;
    width: 100%;
    pointer-events: none;
  }

  &:hover .listActionScene div {
    opacity: 1;
  }
  &.video {
    ${FlexRowCenter};
    video {
      width: ${props =>
        props.isFullWidth ? 'calc(100% - 2px)' : 'calc(100% - 1.5rem)'};
      object-fit: cover;
    }
  }
  ${OverlayThumbWrapper} {
    z-index: 0;
    .overlay-icon {
      opacity: 0.5;
      font-size: 18px;
    }
  }

  .icon-othertype {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 100%;
    padding: 4px 6px;
    color: var(--color-smoke);
    .--icon {
      font-size: 18px;
    }
    .--name {
      font-size: 12px;
    }
  }
`;
